import React from "react";

// Component imports
//import AppFeature from "../components/app_feature";

export default class AppFeaturesSection extends React.Component {
  
  render() {
     let row1Item1 = this.props.children[0];
     let row1Item2 = this.props.children[1];
     let row1Item3 = this.props.children[2];
     let row2Item1 = this.props.children[3];
     let row2Item2 = this.props.children[4];
     let row2Item3 = this.props.children[5];

    return (
        <section id="features-section" className="py-5 bg-light">
        <div className="container text-dark">
            <div className="row">
                {row1Item1}
                {row1Item2}
                {row1Item3}
            </div>
        </div>
        <div className="container text-dark">
            <div className="row">
                {row2Item1}
                {row2Item2}
                {row2Item3}
            </div>
        </div>
    </section>
    );
  }
}
