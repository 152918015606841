import React from 'react'
import {StaticQuery, graphql} from 'gatsby';
import Img from 'gatsby-image';

// fixed(width: 300, height: 300) {
//                     ...GatsbyImageSharpFixed_tracedSVG
// }
const getMosaic = graphql`
{
          heroImage:file(
            relativePath: { eq: "img/my_habits/my_habits_mosaic.png" }
          ) {
            childImageSharp {
              fluid(maxWidth: 944) {
                ...GatsbyImageSharpFluid_tracedSVG
              }
            }
          }
        }
`;

export default function hero_mosaic() {
  return (
    <StaticQuery
      query={getMosaic}
      render={data => {
        return (
            <div style={{ maxWidth: 944 }}>
              <Img fluid={data.heroImage.childImageSharp.fluid} className="img-fluid pt-4 mt-2" />
            </div>
        ) 
      }
    }
    />
  )
};

