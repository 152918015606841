import React from "react";
import { Link } from "gatsby";

// Component imports

// CSS imports
import "../../assets/sass/app_page.scss";

export default class AppFAQSection extends React.Component {
  render() {
    let moreButton = null;
    if (this.props.live) {
      moreButton = (
        <Link
          to={this.props.morePage}
          className="btn btn-outline-tss text-uppercase"
        >
          More
        </Link>
      );
    }
    return (
      <section id="faq-section">
        <div className="dark-overlay">
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="container pt-5">
                  <h1 className="text-uppercase font-weight-light">
                    Frequently Asked Questions
                  </h1>
                  <p className="font-weight-light">{this.props.lead}</p>
                </div>
              </div>
            </div>
            <div className="row">{this.props.children}</div>
            <div className="row pb-5">
              <div className="col text-center">
                <div className="text-center">{moreButton}</div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
