import React from "react";
import { graphql } from "gatsby";
//import Img from "gatsby-image";

// Component imports
import MyHabitsLayout from "../../components/my_habits/MyHabitsLayout";
import AppHeroSectionFluid from "../../components/app_pages/AppHeroSectionFluid";
import AppSeparator from "../../components/app_pages/AppSeparator";
import AppFeaturesHeader from "../../components/app_pages/AppFeaturesHeader";
import AppFeaturesSection from "../../components/app_pages/AppFeaturesSection";
import AppFeature from "../../components/app_pages/AppFeature";
import AppFAQSection from "../../components/app_pages/AppFAQSection";
//import AppFAQItem from "../../components/app_pages/AppFAQItem";
import HeroImage from "../../components/my_habits/hero_mosaic";
import SEO from "../../components/common/seo";

// Image imports
//import MosaicImage from "../../assets/img/my_habits/my_habits_mosaic.png";
import ComingSoonMosaicImage from "../../assets/img/my_habits/mosaic_coming_soon.png";
import AppIcon from "../../assets/img/my_habits/MyHabits300x300.png";

// CSS imports
//import { FaBolt } from "react-icons/fa";

export default ({ data }) => {
  //let edges = data.top_faq_items ? data.top_faq_items.edges : [];

  return (
    <MyHabitsLayout footerPositioning="bottom">
      <SEO title="My-Habits Home" keywords={[`myhabits`, `my-habits`]} />
      <AppHeroSectionFluid
        sectionClass="my_habits"
        useDarkMode={false}
        heroTitle="My-Habits"
        appIcon={AppIcon}
        image={ComingSoonMosaicImage}
        heroText="Do one thing each day - Track it - Change your life."
        items= { [
          'Create habits you want to develop to "move the bar"',
          "Easily track your progress",
          "Simple swipe left/right to set a habit's status",
          'Export results to hard copy for your favorite "analog" system'
        ]}
        actionURL="https://itunes.apple.com/us/app/my-habits/id1394961520?mt=8"
      >
        <HeroImage />
      </AppHeroSectionFluid>
      <AppSeparator
        useDarkMode={true}
        actionText="Make Great Habits - Become a Better You"
        actionLead="Available for FREE on the app store. Exclusively for iPhone."
        installURL="https://itunes.apple.com/us/app/my-habits/id1394961520?mt=8"
      />
      <AppFeaturesHeader
        sectionClass="my_habits"
        title="Features"
        lead="My-Habits includes features that makes habit tracking a breeze!"
      />
      <AppFeaturesSection>
        <AppFeature
          faIcon="fa fa-bolt"
          title="Multiple Habits"
          description="Track multiple habits"
        />
        <AppFeature
          faIcon="far fa-clock"
          title="Customizable Frequency"
          description="Schedule on a weekly or monthly basis."
        />
        <AppFeature
          faIcon="fas fa-chart-line"
          title="Graphs"
          description="Choose different graphs to see how you are doing compared to the past."
        />
        <AppFeature
          faIcon="fas fa-print"
          title="Print"
          description="Print the monthly habit tracker for use in your bullet journal."
        />
        <AppFeature
          faIcon="fas fa-calendar-alt"
          title="Exception Days"
          description="Need to take a day off, no-problem, just schedule an exception day."
        />
        <AppFeature
          faIcon="fas fa-cloud"
          title="Sync"
          description="Sync your data between multiple devices."
        />
      </AppFeaturesSection>
      <AppFAQSection
        lead="Find the answers to your My-Habits questions and learn best practices from other users."
        morePage="my_habits/my_habits_faq"
        live={false}
      >
        <div className="container text-light">
          <span>Coming Soon</span>
        </div>
        {/* {edges.map(({ node }) => {
          return (
            <AppFAQItem
              question={node.frontmatter.title}
              answer={node.excerpt}
            />
          );
        })} */}
      </AppFAQSection>
    </MyHabitsLayout>
  );
};

export const query = graphql`
  {
    top_faq_items: allMarkdownRemark(
      limit: 3
      filter: { fileAbsolutePath: { glob: "**/md/my_habits/faq_*.md" } }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          frontmatter {
            date
            title
          }
          id
          excerpt
        }
      }
    }
  }
`;
