import React from "react";
//import { graphql } from "gatsby";
//import Img from "gatsby-image";

// Component imports
import AppDownloadBadge from "./AppDownloadBadge"
// Image imports

// CSS imports
import "../../assets/sass/app_page.scss";

export default class AppHeroSectionFluid extends React.Component {
  render() {
    let sectionClass = `hero-section ${this.props.sectionClass}`;

    let textColor = "text-dark";
    if (this.props.useDarkMode) {
      textColor = "text-light";
    }
    
    let itemsToShow = [];
    if (this.props.items) {
      itemsToShow = this.props.items;
    }
    let lineItems = itemsToShow.map(item => {
      return (
        <div className="d-flex d-row">
          <div className="p-2 h3">
            <em className="far fa-check-circle text-success" />
          </div>
          <div className="p-2">
            <span className={`h4 ${textColor} font-weight-light`}>
              {item}
            </span>
          </div>
        </div>
      );
    });

    return (
      <section className={sectionClass}>
        <div>
          <div className="container-fluid mx-3">
            <div className="row">
              <div className="col-md-6 my-auto pt-5 pl-5">
                <div className="container">
                  <div className="row align-items-center">
                    <div className="col-md-auto">
                      <img
                        src={this.props.appIcon}
                        alt=""
                        className="img-fluid rounded hero-app-icon"
                      />
                    </div>

                    <div
                      className={`col ${textColor} text-left display-2 pl-0 font-weight-light`}
                    >
                      {this.props.heroTitle}
                    </div>
                  </div>
                </div>
                <div className="mx-auto">
                  <h2 className={`${textColor} align-middle font-weight-light`}>
                    {this.props.heroText}
                  </h2>
                  {lineItems}
                  <div className="mt-4 text-center">
                    <AppDownloadBadge actionURL={this.props.actionURL} />
                  </div>
                </div>
              </div>
              <div className="col-md-6 pt-5 pb-3 pr-5 d-none d-md-block align-self-center">
                {this.props.children}
                {/* <img src={this.props.image} alt="" className="img-fluid pt-4" /> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

// export const query = graphql`
//   query($name: String!) {
//     heroImage: allFile(filter: { name: { eq: $name } }) {
//       edges {
//         node {
//           id
//           relativePath
//           name
//           childImageSharp {
//             fixed(width: 3805, height: 2980) {
//               ...GatsbyImageSharpFixed_tracedSVG
//             }
//           }
//         }
//       }
//     }
//   }
// `;
