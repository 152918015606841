import React from "react";

export default class AppFeaturesHeader extends React.Component {
  render() {
    let sectionClass = `features-heading ${this.props.sectionClass}`;

    return (
      <section className={sectionClass}>
        <div className="dark-overlay">
          <div className="row">
            <div className="col">
              <div className="container pt-5">
                <h1 className="font-weight-light">{this.props.title}</h1>
                <p className="h4 pt-2 font-weight-light">{this.props.lead}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
