import React from 'react';

// Image imports
import comingSoonImg from "../../assets/img/common/app_store_coming_soon.png";

export default class AppDownloadBadge extends React.Component {
    render () {
        if (this.props.actionURL) {
            return (
                <a
                    href={this.props.actionURL}
                    alt="Download now"
                    className="app-link"
                >""</a>
            );
        }
        return (<img src={comingSoonImg} alt="coming soon" />);
    }
}
