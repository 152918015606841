import React from "react";

// Component imports
import AppDownloadBadge from "./AppDownloadBadge"

// Image imports

export default class AppSeparator extends React.Component {

  render() {

      let sectionClass = "app-separator-light text-dark"
      if (this.props.useDarkMode) {
        sectionClass = "app-separator-dark text-light"
      }
    return (
      <section className={sectionClass}>
        <div className="container">
          <div className="row">
            <div className="col text-center">
              <div className="py-5">
                <h2 className="display-5 text-uppercase font-weight-light">
                  {this.props.actionText}
                </h2>
                <p className="h4 font-weight-light">
                  {this.props.actionLead}
                </p>
                <div className="text-center pt-4">
                  <AppDownloadBadge actionURL={this.props.installURL} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
