import React from "react";

export default class AppFeature extends React.Component {

  render() {
    let className = `${this.props.faIcon} mb-2`;
    console.log(this.props);
    return (
        <div className="col-md-4 mb-4 text-center">
          <span className="mb-2 display-4"><i className={className}></i></span>
            {/* <i className={className}></i> */}
            <h3 className="font-weight-light">{this.props.title}</h3>
            <p>{this.props.description}</p>
        </div>
    );
  }
}
